import React, { useEffect } from 'react';
import { CoreLayoutProps, useAuthProvider, useRedirect } from 'react-admin';
import { Layout } from '@blackbox-vision/ra-layout';

import { useMenuItemsContext } from '../../../core/context/menu';

import { useStyles } from './styles';

type Props = CoreLayoutProps & {
  [key: string]: any;
};

// TODO: check when sidebar is open to pass style information
let AppLayout: React.FC<Props> = (props: Props) => {
  let menuItems = useMenuItemsContext();
  let styles = useStyles();

  let authProvider = useAuthProvider();
  let redirect = useRedirect();

  useEffect(() => {
    authProvider.checkAuth({}).catch(() => redirect('/login'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  let iconStyle = {
    color: '#3944A5',
  };

  return (
    <Layout
      {...(props as any)}
      items={menuItems}
      iconStyle={iconStyle}
      layoutStyles={styles}
      sidebarOpenWidth={240}
      showDividers={false}
      logo="/logo.webp"
      logoStyle={{
        height: 40,
        width: 150,
        objectFit: 'contain',
      }}
    />
  );
};

AppLayout.displayName = 'AppLayout';

export default AppLayout;
