import {
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  ListAltOutlined,
  MonetizationOnOutlined,
  MoneyOutlined,
  ReportProblemOutlined,
  SupervisedUserCircleOutlined,
  SupervisorAccountOutlined,
  TimelineOutlined,
} from '@material-ui/icons';

export let menuItems = [
  {
    type: 'resource',
    name: 'investments',
    icon: MonetizationOnOutlined,
    label: 'Inversiones',
    filter: {},
  },
  {
    type: 'resource',
    name: 'interests',
    icon: TimelineOutlined,
    label: 'Intereses',
    filter: {},
  },
  {
    type: 'group',
    label: 'Transacciones',
    items: [
      {
        type: 'resource',
        name: 'transactions',
        icon: ReportProblemOutlined,
        label: 'Pendientes',
        filter: {
          status: 'pending',
        },
      },
      {
        type: 'resource',
        name: 'transactions',
        icon: CheckCircleOutlined,
        label: 'Exitosas',
        filter: {
          status: 'success',
        },
      },
      {
        type: 'resource',
        name: 'transactions',
        icon: ErrorOutlineOutlined,
        label: 'Rechazadas',
        filter: {
          status: 'error',
        },
      },
    ],
  },
  {
    type: 'group',
    label: 'Productos',
    items: [
      {
        type: 'resource',
        name: 'products',
        icon: ListAltOutlined,
        label: 'Paquetes',
        filter: {},
      },
      {
        type: 'resource',
        name: 'currencies',
        icon: MoneyOutlined,
        label: 'Monedas',
        filter: {},
      },
    ],
  },
  {
    type: 'group',
    label: 'Usuarios',
    items: [
      {
        type: 'resource',
        name: 'users',
        icon: SupervisedUserCircleOutlined,
        label: 'Inversores',
        filter: {
          isAdmin: false,
        },
      },
      {
        type: 'resource',
        name: 'users',
        icon: SupervisorAccountOutlined,
        label: 'Administradores',
        filter: {
          isAdmin: true,
        },
      },
    ],
  },
  // TODO: enable when cron job are ready
  // {
  //   type: 'group',
  //   label: 'Configuración',
  //   items: [
  //     {
  //       type: 'resource',
  //       name: 'jobs-config',
  //       icon: TimerOutlined,
  //       label: 'Tareas',
  //     },
  //   ],
  // },
];
