import { createTheme } from '@material-ui/core';

import { palette } from '../theming/palette';
import { typography } from '../theming/typography';

export let theme = createTheme({
  palette,
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: 'white',
          fontFamily: "'Ubuntu', sans-serif",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: palette.primary.main,
      },
    },
    MuiToolbar: {
      dense: {
        minHeight: 56,
      },
    },
    MuiDrawer: {
      paper: {
        height: '100% !important',
        borderBottomRightRadius: 20,
      },
    },
    MuiFormControl: {
      root: {
        marginRight: 16,
      },
    },
    MuiSelect: {
      select: {
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    RaBooleanField: {
      root: {
        display: 'initial',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#FFF',
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: 8,
      },
      input: {
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiTablePagination: {
      input: {
        margin: 0,
      },
    },
    RaPaginationActions: {
      currentPageButton: {
        color: palette.secondary.main,
        fontWeight: 'bold',
        fontSize: 16,
      },
    },
    MuiTableCell: {
      sizeSmall: {
        textAlign: 'center',
        height: 50,
      },
      head: {
        fontWeight: 'bold',
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: '#FFFFFF',
        backgroundColor: '#3944A5',
      },
    },
    MuiLink: {
      underlineHover: {
        color: '#3944A5',
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 9000,
      },
    },
    RaSimpleFormIterator: {
      form: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      index: {
        display: 'none',
      },
    },
    RaImageInput: {
      dropZone: {
        border: '1px dashed #BABABA',
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          position: 'absolute',
          top: '100%',
        },
        '&$contained': {
          position: 'absolute',
          top: '75%',
        },
      },
    },
  },
} as any);
