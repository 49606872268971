import { FirebaseAuthProvider as createAuthProvider } from 'react-admin-firebase';
import firebase from 'firebase/app';
import 'firebase/auth';

import { firebaseAppCredentials } from '../config/firebase';
import { dataProvider } from './data';

let createUserFirebaseApp: any = null;

try {
  createUserFirebaseApp = firebase.initializeApp(
    firebaseAppCredentials,
    'createUserOnly'
  );
} catch (err) {
  createUserFirebaseApp = firebase.app('createUserOnly');
}

let baseAuthProvider = createAuthProvider(firebaseAppCredentials, {
  logging: true,
});

export let authProvider = {
  ...baseAuthProvider,
  checkError: async (params: any) => {
    return await baseAuthProvider.checkError(params);
  },
  checkAuth: async (params: any) => {
    let user: any = await baseAuthProvider.checkAuth(params);

    return !!user
      ? Promise.resolve()
      : Promise.reject({
          redirectTo: '/login',
        });
  },
  getPermissions: async (params: any) => {
    return await baseAuthProvider.getPermissions(params);
  },
  getIdentity: async () => {
    let { uid } = await baseAuthProvider.getAuthUser();

    let { data: user } = await dataProvider.getOne('users', {
      id: uid,
    });

    return {
      id: uid,
      avatar: ``,
      fullName: `${user.fullName ?? ''}`,
      isAdmin: user.isAdmin ?? false,
    };
  },
  sendPasswordResetEmail: async ({ email }: any) => {
    await firebase.auth().sendPasswordResetEmail(email);
  },
  createUser: async (
    { email, password, fullName }: any,
    shouldSendVerificationEmail: boolean = true
  ) => {
    let { user } = await createUserFirebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);

    await user?.updateProfile({
      displayName: fullName,
    });

    if (!user?.emailVerified && shouldSendVerificationEmail) {
      await user?.sendEmailVerification();
    }

    await createUserFirebaseApp.auth().signOut();

    return user;
  },
};
