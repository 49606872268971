import React, { useState } from 'react';
import { email, Notification, required, TextInput } from 'react-admin';
import { Form } from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  Typography,
  Card,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';

import { theme } from '../../core/theming';

import { useStyles } from './styles';
import { useAuth } from './hook';

let AuthLayout = ({
  loading,
  onSubmit,
  resetPassword,
  setResetPassword,
  hasValidationErrors,
  children,
}: any) => {
  let styles = useStyles();

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.headerContainer}>
          <img
            alt="logo"
            style={{
              width: 250,
              height: 75,
              objectFit: 'contain',
            }}
            src="/logo.webp"
          />
          <Typography
            style={{ marginTop: 16 }}
            variant="h5"
            component="h1"
            align="center"
            paragraph
          >
            {resetPassword ? 'Recuperar contraseña' : 'Ingresar'}
          </Typography>
        </div>
        {children}
        <CardActions className={styles.actions}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading || hasValidationErrors}
            fullWidth
            className={styles.submitButton}
            onClick={onSubmit}
          >
            {loading ? (
              <CircularProgress size={25} thickness={4} />
            ) : resetPassword ? (
              'Recuperar Contraseña'
            ) : (
              'Ingresar'
            )}
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={loading}
            fullWidth
            className={styles.submitButton}
            onClick={() => setResetPassword(!resetPassword)}
          >
            {resetPassword ? 'Iniciar Sesión' : 'Olvide mi contraseña'}
          </Button>
        </CardActions>
      </Card>
      <Notification />
    </div>
  );
};

let Login = ({ loading }: any) => {
  let [showPassword, setShowPassword] = useState(false);
  let styles = useStyles();

  return (
    <div className={styles.content}>
      <TextInput
        autoFocus
        source="username"
        label={'Email'}
        disabled={loading}
        className={styles.input}
        validate={[required(), email()]}
        fullWidth
      />
      <TextInput
        source="password"
        label={'Contraseña'}
        disabled={loading}
        validate={[required()]}
        type={showPassword ? 'text' : 'password'}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

let ResetPassword = ({ loading }: any) => {
  let styles = useStyles();

  return (
    <div className={styles.content}>
      <TextInput
        source="email"
        label={'Email'}
        disabled={loading}
        className={styles.input}
        validate={[required(), email()]}
        fullWidth
      />
    </div>
  );
};

export default function CustomLogin() {
  let { loading, resetPassword, setResetPassword, handleSubmit } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, hasValidationErrors }) => (
          <AuthLayout
            loading={loading}
            resetPassword={resetPassword}
            hasValidationErrors={hasValidationErrors}
            onSubmit={handleSubmit}
            setResetPassword={setResetPassword}
          >
            {resetPassword ? (
              <ResetPassword loading={loading} />
            ) : (
              <Login loading={loading} />
            )}
          </AuthLayout>
        )}
      />
    </ThemeProvider>
  );
}
