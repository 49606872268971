import { FirebaseDataProvider as createDataProvider } from 'react-admin-firebase';

import { firebaseAppCredentials } from '../config/firebase';

export let dataProvider = createDataProvider(firebaseAppCredentials, {
  logging: false,
  softDelete: false,
  persistence: 'local',
  metaFieldCasing: 'camel',
  associateUsersById: false,
  dontAddIdFieldToDoc: false,
  lazyLoading: {
    enabled: false,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
  renameMetaFields: {
    created_at: 'createdAt',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
    updated_by: 'updatedBy',
  },
});
