import spanishMessages from '@blackbox-vision/ra-language-spanish';

export let esMessages = {
  ...spanishMessages,
  ra: {
    ...spanishMessages.ra,
    page: {
      ...spanishMessages.ra.page,
      loading: 'Cargando',
    },
    message: {
      ...spanishMessages.ra.message,
      loading: 'La página se está cargando, espere un momento por favor',
    },
  },
  auth: {
    forget_password: {
      success_message: 'Se ha enviado un email a tu casilla de correo',
      error_message: 'Parece que algo ha salido mal, reintente más tarde',
    },
  },
  resources: {
    users: {
      name: 'Usuarios',
      import: {
        success: 'Usuarios importados exitosamente',
        alreadyInUseError:
          'Algunos de los usuarios que desea importar ya existen',
        error: 'Ha ocurrido un error durante la importación de usuarios',
      },
      fields: {
        id: 'Memo',
        email: 'Email',
        country: 'País',
        password: 'Contraseña',
        phoneNumber: 'Celular',
        isAdmin: 'Administrador',
        fullName: 'Nombre Completo',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
        identityDocument: {
          value: 'Número de Documento',
          type: 'Tipo de Documento',
        },
      },
    },
    currencies: {
      name: 'Monedas',
      fields: {
        id: 'ID',
        logo: 'Logo',
        'logo.src': 'Logo',
        name: 'Abreviatura',
        fullName: 'Nombre Completo',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
        'binancePayQr.src': 'QR de Binance Pay',
        coingeckoId: 'Monedas de Coin Gecko',
      },
    },
    'jobs-config': {
      name: 'Tareas',
      fields: {
        id: 'ID',
        name: 'Nombre',
        description: 'Descripción',
        expression: 'Tiempo de Ejecucion',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
      },
    },
    products: {
      name: 'Productos',
      fields: {
        id: 'ID',
        logo: 'Logo',
        'logo.src': 'Logo',
        fullName: 'Nombre Completo',
        interestType: 'Tipo de Interés',
        interestAmount: 'Monto de Interés',
        currencies: 'Monedas Asociadas',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
      },
    },
    transactions: {
      name: 'Transacciones',
      fields: {
        id: 'ID',
        uid: 'Usuario',
        product: 'Producto',
        currency: 'Moneda',
        action: 'Acción',
        status: 'Estado',
        amount: 'Monto',
        binanceId: 'ID de Binance',
        binanceMemo: 'Memo',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
      },
    },
    investments: {
      name: 'Inversiones',
      fields: {
        id: 'ID',
        uid: 'Usuario',
        product: 'Producto',
        currency: 'Moneda',
        amount: 'Monto',
        interest: 'Interés Generado',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
      },
    },
    interests: {
      name: 'Intereses',
      fields: {
        id: 'ID',
        uid: 'Usuario',
        investmentId: 'Inversión',
        amount: 'Interés generado (en BUSD)',
        createdAt: 'Fecha de Creación',
        updatedAt: 'Fecha de Actualización',
        liquidated: 'Liquidado',
      },
    },
  },
};
