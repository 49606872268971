import React from 'react';
import { ResourceProps } from 'react-admin';

let resourceProps: ResourceProps = {
  name: 'investments',
  list: React.lazy(
    () => import(/* webpackChunkName: "investments-list" */ './list')
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "investments-show" */ './show')
  ),
  edit: React.lazy(
    () => import(/* webpackChunkName: "investments-edit" */ './edit')
  ),
};

export default resourceProps;
