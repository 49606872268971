import { makeStyles } from '@material-ui/core';

export let useStyles = makeStyles({
  sidebarWhenClosed: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 0,
  },
  sidebarWhenOpen: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 0,
  },
  appBar: {
    backgroundColor: '#FFFFFF',
  },
  listItem: {
    color: '#212121',
    width: 240,
  },
  menuLink: {
    color: '#212121',
    width: 240,
    fontSize: 20,
  },
  logoutButton: {
    position: 'absolute',
    bottom: 0,
  },
});
