import { useState } from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';

export let useAuth = () => {
  let notify = useNotify();
  let redirect = useRedirect();
  let authProvider = useAuthProvider();

  let [loading, setLoading] = useState(false);
  let [resetPassword, setResetPassword] = useState(false);

  let handleLogin = async (values: any) => {
    try {
      await authProvider?.login?.(values);

      let user: any = await authProvider?.getIdentity?.();

      if (!user?.isAdmin) {
        throw new Error('invalid user');
      }

      redirect(`/transactions?filter={"status":"pending"}`);
    } catch (err: any) {
      notify('ra.auth.sign_in_error', 'warning');
    } finally {
      setLoading(false);
    }
  };

  let handlePasswordReset = async (values: any) => {
    try {
      await authProvider.sendPasswordResetEmail(values);

      notify('auth.forget_password.success_message', 'info');

      setResetPassword(false);
    } catch (err: any) {
      notify('auth.forget_password.error_message', 'warning');
    } finally {
      setLoading(false);
    }
  };

  let handleSubmit = async (values: any) => {
    setLoading(true);

    if (resetPassword) {
      await handlePasswordReset(values);
    } else {
      await handleLogin(values);
    }
  };

  return {
    loading,
    resetPassword,
    handleSubmit,
    setResetPassword,
  };
};
